
.Tiles, .Tiles-2 {
    display: grid;
    grid-gap: 30px;
    color: white;
}

.Tiles-2 {
    margin-top: 30px !important;
}

/*@media only screen and (min-width: 700px) {*/

/*    .Tiles {*/
/*        grid-gap: 4%;*/
/*        grid-template-columns: 48% 48%;*/
/*    }*/

/*    .Tiles-2 {*/
/*        grid-gap: 4%;*/
/*        grid-template-columns: 48% 48%;*/
/*    }*/
/*}*/

@media only screen and (min-width: 950px) {

    .Tiles {
        margin: 30px auto;
        grid-gap: 2%;
        grid-template-columns: 32% 32% 32%;
    }

    .Tiles-2 {
        margin: 30px 0 !important;
        position:relative;
        left: 50%;
        transform: translate(-33.5%, 0);
        margin-top: 30px !important;
        margin: 30px auto;
        grid-gap: 2%;
        grid-template-columns: 32% 32%;
    }


}