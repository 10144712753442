/* Fonts
-----------------------------------------------*/

@font-face {
    font-family: 'MyanmarMN';
    src: url('MyanmarMN.woff2') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

@font-face {
    font-family: 'Gotham Book';
    src: url('Gotham-Book.woff2') format('woff2'),
    url('Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/* Resetter
-----------------------------------------------*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.5s ease;
}


/* Body
-----------------------------------------------*/

body {
    font-size: 15px;
    /*font-family: 'Nunito', sans-serif;*/
    font-family: 'Gotham Book', sans-serif;
    color: #374442;
    padding-bottom: 80px;
}


/* Headings, Paragraphs and Anchors
-----------------------------------------------*/

h1 {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    /*font-family:  MyanmarMN;*/
    font-family: Gotham Book;
}

p {
    font-size: 14px;
    color: #374442;
    text-align: center;
    margin: 10px 0 20px;
}

a {
    text-decoration: none;
    color: inherit;
}

.Container {
    max-width: 1440px;
    padding: 0 25px;
    width: 100%;
    margin: 0 auto;
}


.button {
    background-color: #225728;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 4px;
    border: 0;
    color: #FFF;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
}

.button--text {
    background-color: inherit;
    color: inherit;
}

.button--delete {
    color: #BC2354;
}

@media only screen and (min-width: 600px) {

    h1 {
        font-size: 40px;
    }


}