.Tile {
    transition: all 0.3s ease;
    background-color: #DDD;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Tile:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transform: translateY(-5px);
}

.Tile:hover .Tile__icon {
    /*transform: scale(1.3);*/
}

.Tile__icon {
    text-align: center;
    margin-bottom: 10px;
    transition: all 0.5s ease;
}

.Tile__name {
    text-transform: uppercase;
    text-align: center;
    /*font-family:  MyanmarMN;*/
    font-family: 'Gotham Book';
    font-size: 16px;
    margin-bottom: 10px;
}

.Tile__body {
    display: flex;
    flex-direction: column;
    xjustify-content: center;
    /*background-color: rgba(255, 255, 255, 0.75);*/
    margin: 30px 30px 0 30px;
    padding: 20px;
    height: 220px;
}

.Tile__icon_image {
    width: auto;
    height: 45px;
    margin-top: 10px;
    xborder: 1px solid black;
}

.Tile__description {
    text-align: center;
}


/* Ordering - Blue Theme */

.Tile.Tile--ordering {
    background-image: url('../../assets/bg-ordering.svg');
    background-color: #23582c;
}


/* Lounge - Red Theme */

.Tile.Tile--lounge {
    background-image: url('../../assets/bg-lounge.svg');
    background-color: #f17922;
}


/* Social - Teal Theme */

.Tile.Tile--social {
    background-image: url('../../assets/bg-social.svg');
    background-color: #eb6752;
}


/* Market - Green Theme */

.Tile.Tile--market {
    background-image: url('../../assets/bg-market.svg');
    background-color: #4a9e46;
}

.Tile.Tile--market .Tile__icon_image {
    /*height: 50px;*/
}


/* AMI - Orange Theme */

.Tile.Tile--ami {
    background-image: url('../../assets/bg_penny2.svg');
    background-color: #1d344d;
}


/* Stock - Green Theme */

.Tile.Tile--stock {
    background-image: url('../../assets/bg-stock.svg');;
    background-color: #df1e4a;
}

.Tile.Tile--stock .Tile__icon_image {
    height: 40px;
}


@media only screen and (min-width: 750px) {

    .Tile {
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 1260px) {
    .Tile__name {
        font-size: 24px;
    }
}